.ModalDownload .mt-05rem {
  margin-top: 0.5rem;
}

.ModalDownload .mb-0rem {
  margin-bottom: 0rem;
}

.ModalDownload .mr-05rem {
  margin-right: 0.5rem;
}
