.GetPaginationDownload .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #6c757c;
  border-color: #dee2e6;
}

.GetPaginationDownload .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #707980;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.GetPaginationDownload .ml-05rem {
  margin-left: 0.5rem;
}

.GetPaginationDownload .mt-01rem {
  margin-top: 0.1rem;
}
