.ImportCrewMemberDataComp .card-header {
  font-weight: bold;
}

.ImportCrewMemberDataComp .search-option-value {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ImportCrewMemberDataComp .ml-1rem {
  margin-left: 1rem;
}

.ImportCrewMemberDataComp .ml-15rem {
  margin-left: 1.5rem;
}

.ImportCrewMemberDataComp .mb-1rem {
  margin-bottom: 1rem;
}

.ImportCrewMemberDataComp .mt-1rem {
  margin-top: 1rem;
}

.ImportCrewMemberDataComp .mb-025rem {
  margin-bottom: 0.25rem;
}

.ImportCrewMemberDataComp .overflow-500 {
  max-height: 300px;
  overflow: scroll;
  font-size: small;
}

.ImportCrewMemberDataComp .custom-file {
  margin-bottom: 25px;
}
