@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    width: 100%;
  }
}

.App .navbar-brand {
  font-weight: bold;
}

.App .navbar {
  background-color: #1e3575 !important;
}
